import axios from 'axios'
import React,{useState,useEffect} from 'react'

const FetchProducts = ({apiBaseURL,setAllProductName}) => {

    useEffect(() => {
        fetchPrds()
    }, [])
    

    const fetchPrds = async () => {
        axios
        .get(`${apiBaseURL}/products`)
        .then((response) =>{
            setAllProductName(response.data)
        })
    } 


    return null;
}

export default FetchProducts
