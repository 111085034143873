import React from 'react';
// css
import '../css/landing.css'
// icons
import { FaRegComment,FaRegHeart, FaStar, FaStarHalf, FaCanadianMapleLeaf } from "react-icons/fa";
import { FaQuoteLeft, FaQuoteRight } from "react-icons/fa6";
import { AiOutlineGlobal } from "react-icons/ai";
import { FiSend } from "react-icons/fi";
// assets
// import webIntro from '../assets/vids/How About Visual Cover.mp4'
import webIntro from '../assets/vids/SkinmisoCommercial.mp4'
import glowVid from '../assets/vids/smile.mp4'
import cards from '../assets/imgs/cardGIf.gif'
import rcfoamBack from '../assets/imgs/ricefoamBack.png'
import riceFoam from '../assets/imgs/foam_tube.png'
import user from '../assets/imgs/user.jpg'
import lndPost from '../assets/imgs/skinmisoPost.jpg'
import lndPost1 from '../assets/imgs/skinmisoPost1.jpeg'
import poreBundle from '../assets/imgs/poreBundle.png'


const Landing = () => {
  return (
    <div>
      <div className="landing">
        <div className="mainLanding">
          <div className="landing-container">
            <div className="landing-header">
              <div className="lheadName">
                <FaCanadianMapleLeaf id='canadianMaple1'/>
                <FaCanadianMapleLeaf id='canadianMaple2'/>
                <h1>Skinmiso</h1>
                <h2>canada</h2>
                <p>Join the global skincare revolution with SKINMISO, the ultimate name in Korean beauty. Renowned for our pore-specialized formulas and innovative solutions, SKINMISO offers you the very best in skincare. Discover why millions trust SKINMISO for clear, radiant skin, and try it for yourself. Your journey to flawless skin starts here</p>
                <button>Shop now</button>
              </div>
              <div className="lheader-video">
                <video autoPlay muted loop>
                  <source src={webIntro} type="video/mp4" />
                </video>
              </div>
            </div>
            <div className="landing-contents">
              <section>
                <div className="lndCards-content">
                  <div className="landing-cards">
                    <div className="card right">
                      <img src={cards} alt="" />
                    </div>
                    <div className="card left">
                      <h1>Experience the World’s Best Korean Skincare</h1>
                      <hr />
                      <p>Join the global skincare revolution with SKINMISO, the ultimate name in Korean beauty. Renowned for our pore-specialized formulas and innovative solutions, SKINMISO offers you the very best in skincare. Discover why millions trust SKINMISO for clear, radiant skin, and try it for yourself. Your journey to flawless skin starts here</p>
                    </div>
                  </div>
                  <div className="lndngsliderText">
                    <ul>
                      <li>Designed to illuminate and refine your skin, paving the way to a glass-like sheen</li>
                      <li>Designed to illuminate and refine your skin, paving the way to a glass-like sheen</li>
                      <li>Designed to illuminate and refine your skin, paving the way to a glass-like sheen</li>
                    </ul>
                  </div>
                  <div className="crdSliderbtn">
                    <button>View all</button>
                  </div>
                </div>
              </section>
              <section>
                <div className="lndPromo">
                  <div className="lndpromo left">
                    <div className="b1tk1txt">
                      <h1>Buy 2 Get 1</h1>
                      <h2>Free</h2>
                    </div>
                    <div className="rvpstContainer">
                      <div className="rcfoamReview first">
                        <div className="rcfmRvtxt">
                          <div className="rvuserDet">
                            <div className="usrRevImg">
                              <img src={user} alt="" />
                            </div>
                            <div className="usrRevDet">
                              <h1 id='tpRview'>Mary Agustin</h1>
                              <p><AiOutlineGlobal id='glbalIcon'/> Public</p>
                            </div>
                          </div>
                          <h4>The SKINMISO Rice Foam Cleansing is fantastic for removing makeup and impurities at the end of the day. It leaves my skin feeling fresh and soft without stripping it of moisture. Plus, the subtle rice scent is so soothing. <br /><br />#GlowTogether</h4>
                          <div className="rvPostImg">
                            <img src={lndPost1} alt="" />
                          </div>
                          <div className="rvPstIcons">
                            <ul>
                              <li>
                                <FaRegHeart id='lndrctIcons'/>
                                <p>123</p>
                              </li>
                              <li>
                                <FaRegComment id='lndrctIcons'/>
                                <p>123</p>
                              </li>
                              <li>
                                <FiSend id='lndrctIcons'/>
                                <p>123</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="rcfoamReview second">
                        <div className="rcfmRvtxt">
                          <div className="rvuserDet">
                            <div className="usrRevImg">
                              <img src={user} alt="" />
                            </div>
                            <div className="usrRevDet">
                              <h1 id='tpRview'>Mary Agustin</h1>
                              <p><AiOutlineGlobal id='glbalIcon'/> Public</p>
                            </div>
                          </div>
                          <h4>The SKINMISO Rice Foam Cleansing is fantastic for removing makeup and impurities at the end of the day. It leaves my skin feeling fresh and soft without stripping it of moisture. Plus, the subtle rice scent is so soothing. <br /><br />#GlowTogether</h4>
                          <div className="rvPostImg">
                            <img src={lndPost} alt="" />
                          </div>
                          <div className="rvPstIcons">
                            <ul>
                              <li>
                                <FaRegHeart id='lndrctIcons'/>
                                <p>123</p>
                              </li>
                              <li>
                                <FaRegComment id='lndrctIcons'/>
                                <p>123</p>
                              </li>
                              <li>
                                <FiSend id='lndrctIcons'/>
                                <p>123</p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="crclGlow"></div>
                    </div>
                    <div className="riceFoaminfo">
                      <div className="rcfmContent">
                        <h3>Rice Foam Cleansing</h3>
                        <div className="riceFoamInfo">
                          <hr />
                          <h5>21,895 Sold</h5>
                          <div className="rvStars">
                            <p>Reviews <span>4.75</span></p>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStarHalf id='lndStar'/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="gtstrBtn">
                      <button>Got to Store</button>
                    </div>
                  </div>
                  <div className="lndpromo right">
                    <div className="imgRcfoamBg">
                      <img src={rcfoamBack} alt="" id='rcFoambackround'/>
                      <img src={riceFoam} alt="" id='riceFoamPng'/>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="lndBundle">
                  <div className="prBundle">
                    <div className="prBundle">
                      <h1>Pore Perfection with the SKINMISO Pore Bundle!</h1>
                    </div>
                    <div className="prBundleContent">
                      <div className="prBundleHead">
                        <h1>Get Rice foam for Free!</h1>
                        <p>Buy Pore Purifying Toner, Pore zero-night cream & Pore Coreset and Get Skinmiso Rice Foam Cleansing for free</p>
                      </div>
                      <div className="prBundleImg">
                        <img src={poreBundle} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section>
                <div className="lndStRev">
                  <div className="lndStRevHeader">
                    <h1>Glow Stories & Reviews</h1>
                  </div>
                  <div className="lndRvContents">
                    <ul>
                      <li>
                        <div className="ldnRvSstars">
                          <span>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                          </span>
                        </div>
                        <div className="ldnRvSsContainer">
                          <div className="ldnRvSImg">
                            <span>
                              <img src={user} alt="" />
                            </span>
                          </div>
                          <div className="ldnRvSsContent">
                            <FaQuoteLeft id='lndQtr'/>
                            <h1>Mary</h1>
                            <p>I've struggled with enlarged pores for years, but the SKINMISO Pore Corset Serum is a game-changer! After just a few weeks, my pores look visibly smaller</p>
                            <FaQuoteRight id='lndQtl'/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="ldnRvSstars">
                          <span>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                          </span>
                        </div>
                        <div className="ldnRvSsContainer">
                          <div className="ldnRvSImg">
                            <span>
                              <img src={user} alt="" />
                            </span>
                          </div>
                          <div className="ldnRvSsContent">
                            <FaQuoteLeft id='lndQtr'/>
                            <h1>Mary</h1>
                            <p>I've struggled with enlarged pores for years, but the SKINMISO Pore Corset Serum is a game-changer! After just a few weeks, my pores look visibly smaller</p>
                            <FaQuoteRight id='lndQtl'/>
                          </div>
                        </div>
                      </li>
                      <li>
                        <div className="ldnRvSstars">
                          <span>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                            <FaStar id='lndStar'/>
                          </span>
                        </div>
                        <div className="ldnRvSsContainer">
                          <div className="ldnRvSImg">
                            <span>
                              <img src={user} alt="" />
                            </span>
                          </div>
                          <div className="ldnRvSsContent">
                            <FaQuoteLeft id='lndQtr'/>
                            <h1>Mary</h1>
                            <p>I've struggled with enlarged pores for years, but the SKINMISO Pore Corset Serum is a game-changer! After just a few weeks, my pores look visibly smaller</p>
                            <FaQuoteRight id='lndQtl'/>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section>
                <div className="lndingLstSec">
                  <div className="lndnglstSecR">
                    <h1>Glow Together</h1>
                    <p><span>At SKINMISO, we believe that every dream deserves a chance to shine.</span> That's why we're launching the "Glow Together" campaign – an initiative dedicated to discovering and nurturing aspiring talents. Whether you're a dancer, singer, artist, or athlete, SKINMISO is here to support your journey to success.</p>
                  </div>
                  <div className="lndinglstSecL">
                    <video autoPlay muted loop>
                      <source src={glowVid} type="video/mp4" />
                    </video>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Landing
