import React from 'react'
// css 
import '../../css/droplet.css'

const DropletLoader = () => {
  return (
    <div className="dropletLoader">
        <div className="drploaderMain">
            <div className="drop"></div>
            <div className="wave"></div>
        </div>
    </div>
  )
}

export default DropletLoader