/* global FB */
import React,{useState,useEffect} from 'react'
// css
import '../../css/login-modal.css'
// axios
import axios from 'axios';
// cookies
import Cookies from 'js-cookie'
// icons
import { FaGoogle,
        FaFacebook,
        FaEye,
        FaEyeSlash
} from "react-icons/fa";
import { IoCloseSharp } from "react-icons/io5";
import { BiSolidError } from "react-icons/bi";
// assets
import logo from '../../assets/imgs/SkinmisoPlainCyanLogo.png'

const fbappId = process.env.REACT_APP_FB_APP_ID

const LoginModal = ({openLogModal,setopenLogModal,setCreateSuccess,apiBaseURL}) => {
  // useStates
  // create data
  const [ createEmail,setCreateEmail ]  = useState('')
  const [ createUsername,setCreateUsername ] = useState('')
  const [ createPassword,setCreatePassword ] = useState('')
  const [ useCreateAcc, setUseCreateAcc] = useState('email')
  // show password create
  const [ createShowPassword,setCreateShowpass ] = useState('password')
  // create res
  const [ createError,setCreateError ] = useState('')
  // error input create
  const [ createEmailError,setCreateEmailError ] = useState('')
  const [ createUsernameError,setCreateUsernameError ] = useState('')
  const [ createPasswordError,setCreatePasswordError ] = useState('')

  // login data
  // show pass login
  const [ loginShowPassword,setLoginShowPassword ] = useState('password')
  const [ loginUsername,setLoginUsername ] = useState('')
  const [ loginPassword,setLoginPassword ] = useState('')
  // login res
  const [ loginRes,setLoginRes ] = useState('')
  // error input login
  const [ loginUsernameError,setLoginUsernameError ] = useState('')
  const [ loginPasswordError,setLoginPasswordError ] = useState('')

  // forgot passwird 
  const [ forgotUseEmail,setForgotUseEmail ] = useState('email')
  const [ forgotPassinput,setForgotPassInput ] = useState('')
  const [ forgotPassError, setForgotPassError] = useState('')

  // facebook
  const [sdkLoaded, setSdkLoaded] = useState(false); // To track when SDK is loaded
  const [userProfile, setUserProfile] = useState(null);

  const randomIDGenerator = (length) => {
    const charset =
      "abcdefghijklmnopqrstuvwxyzABCDEFGHIKLMNOPQRSTUVWXYZ0123456789";
    let result = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      result += charset.charAt(randomIndex);
    }
    return result;
  };

  useEffect(() => {
    window.fbAsyncInit = function () {
      window.FB.init({
        appId      : `${fbappId}`,
        cookie     : true,
        xfbml      : true,
        version    : 'v20.0'
      });

      // Log page view event
      window.FB.AppEvents.logPageView();
      setSdkLoaded(true); // Mark SDK as loaded after initialization
      window.FB.getLoginStatus(function (response) {
        statusChangeCallback(response);
      });
    };

    // Load the SDK
    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) {
        return;
      }
      js = d.createElement(s);
      js.id = id;
      js.src = 'https://connect.facebook.net/en_US/sdk.js';
      fjs.parentNode.insertBefore(js, fjs);
    })(document, 'script', 'facebook-jssdk');

  }, []); // Empty dependency array ensures this runs only once

  const statusChangeCallback = (response) => {
    // Handle different login states
    if (response.status === 'connected') {
      const accessToken = response.authResponse.accessToken
      const accToken = {
        accessToken: accessToken
      }
      axios
        .post(`${apiBaseURL}/connect-to-fb`, accToken)
        .then((response) => {
          console.log(response);
          
          const authTokenres = response.data.token;
          const loginSessionres = response.data.loginSession;
          const customerID = response.data.customerID
          const connected = response.data.facebook
          setCreateError('');
          // Assuming response.data.token is valid
          Cookies.set('fb_access_Token', accessToken, { expires: 7 });
          Cookies.set('authToken', authTokenres, { expires: 7 });
          Cookies.set('loginSessionToken', loginSessionres, { expires: 7 });
          localStorage.setItem('currentUser', customerID);

          if (connected) {
            window.location.reload()
          } else {
            setopenLogModal('');
            setCreateSuccess(true);
          }
        })
        .catch((error) => {
          console.log(error);
        })
      // You can make an API request here or use the response
    } else if (response.status === 'not_authorized') {
      console.log('User is logged into Facebook, but authorized');
    }
  };

  // btns
  const createShowpassworbtn = () => {
    setCreateShowpass('text')
  }
  const createHidepassworbtn = () => {
    setCreateShowpass('password')
  }
  const loginShowpassworbtn = () => {
    setLoginShowPassword('text')
  }
  const loginHidepassworbtn = () => {
    setLoginShowPassword('password')
  }

  // create and back to login btn
  const openCreateAcc = () => {
    setopenLogModal('create')
  }  

  const backToLogin = () => {
    setopenLogModal('login')
  }
  const openforgotPass = () => {
    setopenLogModal('forgot')
  }


  // close btn modal
  const closeModal = () => {
    setopenLogModal('')
  }
  
  // log in acc function
  const loginAcc = async (e) => {
    e.preventDefault()

    let loginInfo = {}
    switch (true) {
      case loginUsername === '' && loginPassword === '':
        setLoginUsernameError('Input Username Required Fill')
        setLoginPasswordError('Input Password Required Fill')
        return;
    
      case loginUsername === '':
        setLoginUsernameError('Input Username Required Fill')
        setLoginPasswordError('')
        return;

      case loginPassword === '':
        setLoginUsernameError('')
        setLoginPasswordError('Input Password Required Fill')
        return;

      case loginUsername !== '' && loginPassword !== '':
        loginInfo = {
          username: loginUsername,
          password: loginPassword
        }
        break;
      default:
        break;
    }

    try {
      axios
      .post(`${apiBaseURL}/login`, loginInfo)
      .then((response) =>{
        const successData = response.data.success; 

        if (successData === true) {
          Cookies.set('authToken', response.data.token, { expires: 7 });

          axios.get(`${apiBaseURL}/user-login-access-token`, {
            headers: { 'Authorization': `Bearer ${response.data.token}` }
          })
          .then((response) =>{
            Cookies.set('loginSessionToken', response.data[1], { expires: 7 });
            localStorage.setItem('currentUser', response.data[0]);
            setLoginRes('')
            setopenLogModal('');
            
            setTimeout(() => {
              window.location.reload()
            }, 300);
            
          })
        }
      })
      .catch((response) => {
        const data = response.response.data
        
        setLoginUsernameError('')
        setLoginPasswordError('')
        setLoginRes(data.message)
      })
    } catch (error) {
      console.log(error);
      
    }
  }

  // create acc function
  const createAcc = async (e) => {
    e.preventDefault();
  
    let createCustomerdata = {};
  
    switch (true) {
      case createEmail === '' && createUsername === '' && createPassword === '':
        setCreateEmailError('Input Email Required Fill')
        setCreateUsernameError('Input Username Required Fill')
        setCreatePasswordError('Input Password Required Fill')
        return;
  
      case createEmail === '' && createUsername === '':
        setCreateEmailError('Input Email Required Fill')
        setCreateUsernameError('Input Username Required Fill')
        setCreatePasswordError('')
        return;
  
      case createEmail === '' && createPassword === '':
        setCreateEmailError('Input Email Required Fill')
        setCreatePasswordError('Input Password Required Fill')
        setCreateUsernameError('')
        return;
  
      case createUsername === '' && createPassword === '':
        setCreateUsernameError('Input Username Required Fill')
        setCreatePasswordError('Input Password Required Fill')
        setCreateEmailError('')
        return;

      case createEmail === '':
        setCreateEmailError('Input Email Required Fill')
        setCreateUsernameError('')
        setCreatePasswordError('')
        return;
  
      case createUsername === '':
        setCreateUsernameError('Input Username Required Fill')
        setCreateEmailError('')
        setCreatePasswordError('')
        return;
  
      case createPassword === '':
        setCreatePasswordError('Input Password Required Fill')
        setCreateUsernameError('')
        setCreateEmailError('')
        return;
  
      case createEmail.includes('@') && createEmail.includes('.com'):
        // clean error
        setCreateUsernameError('')
        setCreateEmailError('')
        setCreatePasswordError('')
        // Email provided
        createCustomerdata = {
          mobileno: 'no phone added',
          email: createEmail,
          username: createUsername,
          password: createPassword,
        };
        break;
  
      case /^\d+$/.test(createEmail) && createEmail.length > 5:
        // clean error
        setCreateUsernameError('')
        setCreateEmailError('')
        setCreatePasswordError('')
        // Mobile number provided
        createCustomerdata = {
          mobileno: createEmail,
          email: 'no email added',
          username: createUsername,
          password: createPassword,
        };
        break;
  
      default:
        setCreateError('Invalid email or mobile number');
        return;
    }
  
    try {
      axios
        .post(`${apiBaseURL}/register-acc`, createCustomerdata, {
          headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Content-Type': 'application/json', // Ensure the server receives JSON data
            'Accept': 'application/json', // Expecting a JSON response
          },
        })
        .then((response) => {
          const authTokenres = response.data.token;
          const loginSessionres = response.data.loginSession;
          const customerID = response.data.customerID
          setCreateError('');
          // Assuming response.data.token is valid
          Cookies.set('authToken', authTokenres, { expires: 7 });
          Cookies.set('loginSessionToken', loginSessionres, { expires: 7 });
          localStorage.setItem('currentUser', customerID);

          setopenLogModal('');
          setCreateSuccess(true);
        })
        .catch((error) => {
          console.log(error);

        });
    } catch (error) {
      console.log(error);
    }
  };

  // recover acc
  const recoverAcc = async () => {

  }

  const checkLoginState = () => {
    window.FB.getLoginStatus(function (response) {
      statusChangeCallback(response);
    });
  };
  
  const createUsingfb = async () => {
    if (sdkLoaded) {
      window.FB.login(checkLoginState, { scope: 'public_profile,email' });
    } else {
      console.error('Facebook SDK is not yet loaded.');
    }
  }

  return (
    <div className={`login-modal ${openLogModal}`}>
      <div className="clslgmdl">
        <IoCloseSharp id='clslgmdlicon' onClick={closeModal}/>
      </div>
      <div className="lginModal-main">
        <div className="lgnmdl-container">
          <div className="lgnmdl-header">
            <div className="lgnmdl-logo">
              <img src={logo} alt="" />
            </div>
            <h1>{openLogModal === 'login' ? 'Login' : openLogModal === 'create' ? 'Create' : 'Recover'} your Skinmiso Account</h1>
          </div>
          <section className={`lgMdl ${openLogModal}`}>
            <div className="lgnmdl-content">
              <form onSubmit={loginAcc}>
                <div className="lgnAcc-container">
                  <ul>
                    <p>Username</p>
                    <li>
                      <input type="text" autoComplete='username' placeholder='SkinmisoUser_123' value={loginUsername} onChange={e => setLoginUsername(e.target.value)}/>
                      {loginUsernameError &&(<span><BiSolidError id='ErrIconlog'/> {loginUsernameError}</span>)}</li>
                    <p>Password</p>
                    <li id='lgmdl-pssInput'>
                      <input type={loginShowPassword} autoComplete='current-password' placeholder='password' value={loginPassword} onChange={e => setLoginPassword(e.target.value)} />
                      {loginShowPassword === 'password'? <FaEye id='shwpass' onClick={loginShowpassworbtn}/> : <FaEyeSlash id='shwpass' onClick={loginHidepassworbtn}/>}
                      {loginPasswordError &&(<span><BiSolidError id='ErrIconlog'/> {loginPasswordError}</span>)}
                    </li>
                  </ul>
                  {loginRes !== '' &&(<h4>{loginRes}</h4>)}
                  <button type='submit'>Login</button>
                  <div className="lgmdl-frgPass">
                    <span onClick={openforgotPass}>Forgot Password?</span>
                  </div>
                  <h3>by continuing to log in to Skinmiso. Your accepting our <a href="/">Terms and Conditions</a> and <a href="/">Privacy and Policy</a> of our Website.</h3>
                </div>
              </form>
              <hr />
              <div className="noAccBtn">
                <h2>No Skinmiso Account?</h2>
                <button onClick={openCreateAcc}>Create Account</button>
              </div>
              <div className="loginUsing">
                <p>or continue using</p>
                <button id='gAccbtn'><FaGoogle id='lgmdIcon'/> Google Account</button>
                <button onClick={createUsingfb} id='fbAccbtn'><FaFacebook id='lgmdIcon'/> Facebook Account</button>
              </div>
            </div>
          </section>
          <section className={`crMdl ${openLogModal}`}>
            <div className="lgnmdl-content">
              <form onSubmit={createAcc}>
                <div className="lgnAcc-container">
                  <ul>
                    <div className="changeAccuse">
                      <p>{useCreateAcc === 'email' ? 'Email' : 'Mobile no.'}</p>
                      <p id='swtchuse' onClick={(e) => setUseCreateAcc(prev => (prev === 'email' ? 'phone' : 'email'))}>{useCreateAcc === 'email' ? 'use mobile no.' : 'use email'}</p>
                    </div>
                    <li>
                      <input type={useCreateAcc === 'email' ? "email" : "number"} placeholder={useCreateAcc === 'email' ? 'skinmiso@email.com' : '012344321'} value={createEmail} onChange={e => setCreateEmail(e.target.value)} />
                      {createEmailError &&(<span><BiSolidError id='ErrIconlog'/> {createEmailError}</span>)}
                    </li>
                    <p>Username</p>
                    <li>
                      <input type="text" autoComplete='username' placeholder='SkinmisoUser_123' value={createUsername} onChange={e => setCreateUsername(e.target.value)} />
                      {createUsernameError &&(<span><BiSolidError id='ErrIconlog'/> {createUsernameError}</span>)}
                    </li>
                    <p>Password</p>
                    <li id='lgmdl-pssInput'>
                      <input type={createShowPassword} placeholder='password' minLength={6} autoComplete='new-password' value={createPassword} onChange={e => setCreatePassword(e.target.value)} />
                      {createShowPassword === 'password'? <FaEye id='shwpass' onClick={createShowpassworbtn}/> : <FaEyeSlash id='shwpass' onClick={createHidepassworbtn}/>}
                      {createPasswordError &&(<span><BiSolidError id='ErrIconlog'/> {createPasswordError}</span>)}
                    </li>
                  </ul>
                  {createError !== '' &&(<h4>{createError}</h4>)}
                  <button type='submit'>Create Account</button>
                  <h3>by continuing to creating account to Skinmiso. Your accepting our <a href="/">Terms and Conditions</a> and <a href="/">Privacy and Policy</a> of our Website.</h3>
                </div>
              </form>
              <hr />
              <div className="bacAccBtn">
                <h2 onClick={backToLogin}>back to login</h2>
              </div>
              <div className="loginUsing">
                <p>or sign up using</p>
                <button id='gAccbtn'><FaGoogle id='lgmdIcon'/> Google Account</button>
                <button onClick={createUsingfb} id='fbAccbtn'><FaFacebook id='lgmdIcon'/> Facebook Account</button>
              </div>
            </div>
          </section>
          <section className={`fgpMdl ${openLogModal}`}>
          <div className="lgnmdl-content">
              <form onSubmit={recoverAcc}>
                <div className="lgnAcc-container">
                  <ul>
                    <div className="changeAccuse">
                      <p>{forgotUseEmail === 'email' ? 'Email' : 'Mobile no.'}</p>
                      <p id='swtchuse' onClick={(e) => setForgotUseEmail(prev => (prev === 'email' ? 'phone' : 'email'))}>{forgotUseEmail === 'email' ? 'use mobile no.' : 'use email'}</p>
                    </div>
                    <li>
                      <input type={forgotPassinput === 'email' ? "email" : "number"} placeholder={forgotUseEmail === 'email' ? 'skinmiso@email.com' : '012344321'} value={forgotPassinput} onChange={e => setForgotPassInput(e.target.value)} />
                      {forgotPassError &&(<span><BiSolidError id='ErrIconlog'/> {forgotPassError}</span>)}
                    </li>
                  </ul>
                  {createError !== '' &&(<h4>{createError}</h4>)}
                  <button type='submit'>Search Account</button>
                  <h3>Please input your registered email or mobile number here so Skinmiso can help you recover your account. For more information or need some assistance, contact us <a href="/">here.</a></h3>
                </div>
              </form>
              <hr />
              <div className="bacAccBtn">
                <h2 onClick={backToLogin}>back to login</h2>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  )
}

export default LoginModal
