import React from "react";
// css
import '../src/App.css'
// router
import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';
// js 
import Landing from "./components/pages/landing";
import Nav from "./components/nav";
import Footer from "./components/footer";
import Porecare from "./components/pages/porecare";
import VitaminC from "./components/pages/vitaminC";
import Product from "./components/pages/product";
import Admin from "./components/pages/admin/admin";

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<><Nav /><Landing/><Footer/></>}/>
          <Route path="/porecare" element={<><Nav /><Porecare/><Footer/></>}/>
          <Route path="/vitaminC" element={<><Nav /><VitaminC/><Footer/></>}/>
          <Route path="/admin" element={<><Nav /><Admin/><Footer/></>}/>
          <Route path="/:product" element={<><Nav /><Product/><Footer/></>}/>
        </Routes>
      </div>
    </Router>
  );
}

export default App;
