import React from 'react'
import { Link } from 'react-router-dom'
// css
import './css/footer.css'
// icons
import { FaFacebook,FaInstagram } from "react-icons/fa";
// assets
import ftLogotxt from './assets/imgs/SkinmisoPlainCyanLogo.png'
import ftLogo from './assets/imgs/skinmiso.png'

const Footer = () => {



  return (
    <div className="footer">
        <div className="mainFooter">
            <div className="ftContainer">
                <div className="ftContent">
                    <div className="ftlgimg">
                        <div className="ftlogo">
                            <img src={ftLogo} alt="" />
                        </div>
                        <div className="ftLogotxt">
                            <img src={ftLogotxt} alt="" />
                        </div>
                        <h1>Canada</h1>
                    </div>
                    <ul>
                        <hr />
                        <li>
                            <h1>Cotact Us:</h1>
                            <p><Link>asd</Link></p>
                            <p><Link>asd</Link></p>
                            <p><Link>asd</Link></p>
                        </li>
                        <hr />
                        <li>
                            <h1>Follow Us:</h1>
                            <div className="ftscLgs">
                                <FaFacebook id='ftsocIcons'/>
                                <FaInstagram id='ftsocIcons'/>
                            </div>
                        </li>
                        <li id='feedbackfoot'>
                            <h1>Tell Us More:</h1>
                            <form action="">
                                <textarea name="" id="" placeholder='write to us here'></textarea>
                                <button>send</button>
                            </form>
                        </li>
                    </ul>
                </div>
                <div className="arsft">
                    <p>Skinmiso • All Rights Reserve 2024</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Footer
