import React, { useState, useEffect } from 'react'
// css
import './css/nav.css'
// cookies
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
// icons
import { 
    FaFacebookSquare,
    FaInstagramSquare,
    FaYoutube,
    FaBars,
    FaShoppingBag,
    FaHome,
    FaUser
} from "react-icons/fa";
import { FaCartShopping,
    FaShop,
    FaXTwitter,
    FaNewspaper,
    FaEyeDropper 
} from "react-icons/fa6";
import { RiInkBottleLine } from "react-icons/ri";
import { GiGlowingHands } from "react-icons/gi";
import { IoIosArrowForward } from "react-icons/io";
import { IoSettings,IoLogInOutline } from "react-icons/io5";
// assets
import logoCyan from './assets/imgs/SkinmisoPlainCyanLogo.png'
import sampleImguser from './assets/imgs/user.jpg'
// js file
import LoginModal from './pages/modals/login-modal';
import CartModal from './pages/modals/cart-modal';
import FetchUser from './pages/datafetch/fetchUser';
import SuccessCreateAcc from './pages/handling/successCreate';
import axios from 'axios';
import FetchProducts from './pages/datafetch/fetchProduct';


const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
const Nav = () => {
    const [changeNav, setChangeNav] = useState(false)
    const [sideBar,setSideBar] = useState(false)
    const [viewAllPrd,setViewallprod] = useState(false)
    const [currentUser,setCurrentUser] = useState(null)
    const [openLogModal,setopenLogModal] = useState('')
    const [openCartModal,setOpenCartmodal] = useState(false)
    const [userInfo,setUserInfo] = useState(null)

    // success creation of account
    const [createSuccess,setCreateSuccess] = useState(false)

    const [allproductName,setAllProductName] = useState(null)
    
  
    useEffect(() => {
      const handleScroll = () => {
        const currentScrollHeight = window.scrollY;
  
        if (currentScrollHeight > 600) {
          setChangeNav(true);
        } else {
          setChangeNav(false);
        }
  
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };

      
    }, []); 
    


    // side nav mobile
    const openSidebar = () => {
      setSideBar(true)
    }
    const closeSidenav = () => {
      setSideBar(false)
    }


    // login modal
    const openLoginmodal = () => {
      setopenLogModal('login')
      setSideBar(false)
    }
    const openCreatemodal = () => {
      setopenLogModal('create')
      setSideBar(false)
    }
    const closeLogModal = () => {
      setopenLogModal('')
    }

    // cart modal
    const openCartmodal = () => {
      setOpenCartmodal(true)
    }
    const closeCartmodal = () => {
      setOpenCartmodal(false)
    }


    const viewAllprd = () => {
      setViewallprod(viewAllPrd => !viewAllPrd)
    }


    // log out user
    const logoutUser = async () => {
      const authToken = Cookies.get('authToken');
      
      if (!currentUser && !userInfo) {
        console.log('no user found');
        return;
      }
      
      
      try {
        axios
          .post(`${apiBaseURL}/logout`, 
            { username: userInfo.user_username }, // Send loginUsername in the request body
            {
              headers: {
                'Authorization': `Bearer ${authToken}` // Send the token in the headers
              }
            })
          .then((response) => {
            if (response.data.success === true) {
              // Clear cookies
              Cookies.remove('authToken');
              Cookies.remove('loginSessionToken');
              Cookies.remove('fb_access_Token')
            
              // Clear localStorage
              localStorage.removeItem('currentUser');

              setTimeout(() => {
                window.location.reload()
              }, 100);
            }
          })
          .catch((error) =>{
            console.log(error);
            
          })
    
      } catch (error) {
        console.error('Error in logout:', error.response ? error.response.data.error : error.message);
      }
    };    
    
  return (
    <div className="nav">
      <FetchUser setCurrentUser={setCurrentUser} setUserInfo={setUserInfo} apiBaseURL={apiBaseURL} openLogModal={openLogModal} setopenLogModal={setopenLogModal}/>
      <FetchProducts apiBaseURL={apiBaseURL} setAllProductName={setAllProductName}/>
        <nav>
          <div className={`sccssCrtAcc ${createSuccess}`}>
            <SuccessCreateAcc createSuccess={createSuccess} setCreateSuccess={setCreateSuccess}/>
          </div>
          <div className={`mainNav ${changeNav}`}>
            <section id='lgSec'>
                <div className="navLogo">
                    <a href="/"><img src={logoCyan} alt="" /></a>
                </div>
            </section>
            <section id='pgSec'>  
                <ul>
                  <li id='allprdNav'><a href="/">All Products</a><span className='hoverStyle'></span>
                    {allproductName &&(
                      <div className="allprdNav">
                        {allproductName
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sm_product_name.localeCompare(b.sm_product_name)) // Sort alphabetically
                        .map((prds, i) => (
                          <Link to={`/${prds.sm_product_name.replace(/\s+/g, '-')}`} key={i}><h2>{prds.sm_product_name}</h2></Link>
                        ))}
                      </div>
                    )}
                  </li>
                  <li><a href="/porecare">PoreCare</a><span className='hoverStyle'></span></li>
                  <li><a href="/vitaminC">Vitamin C Line</a><span className='hoverStyle'></span></li>
                  {/* <li><a href="/">Marketplace</a><span className='hoverStyle'></span></li>
                  <li><a href="/">Glow Together</a><span className='hoverStyle'></span></li>
                  <li><a href="/">News & Community</a><span className='hoverStyle'></span></li> */}
                </ul>
            </section>
            <section id='scSec'>
              <ul>
                  <li><a href="/"><FaFacebookSquare/></a></li>
                  <li><a href="/"><FaInstagramSquare/></a></li>
                  <li><a href="/"><FaXTwitter/></a></li>
                  <li><a href="/"><FaYoutube/></a></li>
                  <li onClick={openCartmodal}><FaCartShopping id='cartIcon'/></li>
                  {currentUser && userInfo &&(
                    <li><FaUser id='userIcon'/>
                        <div className="scNavuser">
                          <p>{userInfo.user_username}</p>
                          <p onClick={logoutUser}>Logout</p>
                        </div>
                    </li>
                  )}
                  {!currentUser && !userInfo &&(
                    <li><FaUser id='userIcon' onClick={openLoginmodal}/></li>
                  )}
              </ul>
            </section>
            <section id='mbRSec'>
              <div className="navBar">
                <FaCartShopping id='mbcartIcon' onClick={openCartmodal}/>
                <FaUser  id='mbuserIcon' onClick={openLoginmodal}/>
                <FaBars id='barIcon' onClick={openSidebar}/>
              </div>
            </section>
          </div>
          <div className={`mb-navCartmodal ${openCartModal}`}>
            <CartModal openCartModal={openCartModal}/>
            <div className="clsCartmdl" onClick={closeCartmodal}></div>
          </div>
          <div className={`mb-navLoginmodalNav ${openLogModal}`}>
            {!currentUser &&(<LoginModal openLogModal={openLogModal} setopenLogModal={setopenLogModal} setUserInfo={setUserInfo} setCreateSuccess={setCreateSuccess} apiBaseURL={apiBaseURL}/>)}
            <div className="mbnavLogClose" onClick={closeLogModal}></div>
          </div>
          <div className={`mb-navContainer ${sideBar}`}>
            <div className={`mb-navContents ${sideBar}`}>
              {currentUser && userInfo &&(
                <div className="mb-sideNavAcc">
                  <div className="mb-sdnvUserimg">
                    <img src={sampleImguser} alt="" />
                  </div>
                  <div className="mb-sdnvUserDets">
                    <h1>{userInfo.user_username}</h1>
                    <hr />
                    <p><IoSettings id='sdnvSetIcon'/> Account</p>
                  </div>
                </div>
              )}
              {!currentUser &&(
                <div className="mb-sdnavLogin">
                  <div className="mb-sdNlgError">
                    <h1>Welcome to Skinmiso</h1>
                    <p>Start your beauty journey now</p>
                  </div>
                  <div className="sdnavlgBtns">
                    <button onClick={openLoginmodal}>Login</button>
                    <button onClick={openCreatemodal}>Sign up</button>
                  </div>
                </div>
              )}
              <div className="mb-navLists">
                <ul>
                  <hr />
                  <Link to={'/'}><li onClick={closeSidenav}><FaHome id='sideNavIcon'/>Home</li></Link>
                  <li onClick={viewAllprd}><FaShoppingBag id='sideNavIcon'/>All Products <IoIosArrowForward className={`sideArrowIcon ${viewAllPrd}`}/></li>
                  {allproductName &&(
                    <div className={`sdnvAllprd ${viewAllPrd}`}>
                      {allproductName
                        .slice() // Create a shallow copy to avoid mutating the original array
                        .sort((a, b) => a.sm_product_name.localeCompare(b.sm_product_name)) // Sort alphabetically
                        .map((prod, index) => (
                          <p key={index}>
                            <Link to={`/${encodeURIComponent(prod.sm_product_name.replace(/\s+/g, '-'))}`} onClick={closeSidenav}>
                              {prod.sm_product_name}
                            </Link>
                          </p>
                      ))}
                    </div>
                  )}
                  <Link to={'/porecare'}><li onClick={closeSidenav}><RiInkBottleLine id='sideNavIcon'/>Porecare</li></Link>
                  <Link to={'/vitaminC'}><li onClick={closeSidenav}><FaEyeDropper id='sideNavIcon'/>Vitamin C Line</li></Link>
                  {/* <li><FaShop id='sideNavIcon'/>Marketplace</li>
                  <li><GiGlowingHands id='sideNavIcon'/>Glow Together</li>
                  <li><FaNewspaper id='sideNavIcon'/>News & Community</li> */}
                  {currentUser &&(
                    <>
                      <hr />
                      <li onClick={logoutUser} id='mbLogout'><IoLogInOutline id='sideNavIcon'/>Logout</li>
                    </>
                  )}
                </ul>
              </div>
            </div>
            <div className="clsOutmb" onClick={closeSidenav}></div>
          </div>
        </nav>
    </div>
  )
}

export default Nav
