import React from 'react'
// css
import '../css/porecare.css'
// icons
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { FaStar, FaStarHalf } from "react-icons/fa";
// assets
import poreBundle from '../assets/imgs/Pore Bundle.png'
import porewoman from '../assets/imgs/porewoman.png'
import bundle from '../assets/imgs/Porebund.png'
import organic from '../assets/imgs/100 organic.png'
import cruelty from '../assets/imgs/Cruelty Free.png'
import paraben from '../assets/imgs/Paraben Free.png'
import howto from '../assets/imgs/How to use.png'

import sampleprod1 from '../assets/imgs/Pore Purifying Toner.png'
import sampleprod2 from '../assets/imgs/Pore Zero Night Cream.png'
import sampleprod3 from '../assets/imgs/Pure Vitamin C Serum.png'

import pcmain from '../assets/imgs/pcmain.jpg'
import pcimg1 from '../assets/imgs/pcmain (2).jpg'
import pcimg2 from '../assets/imgs/pcmain (3).jpg'
import pcimg3 from '../assets/imgs/pcmain (4).jpg'
import pcimg4 from '../assets/imgs/pcmain (5).jpg'
import pcimg5 from '../assets/imgs/pcmain (6).jpg'
import pcimg6 from '../assets/imgs/pcmain (7).jpg'


const Porecare = () => {
  return (
    <div className="porecare">
        <div className="porecareMain">
            <div className="poreCare-Container">
                <div className="poreCare-header">
                    <div className="pcBundle">
                        <img src={poreBundle} alt="" />
                        <img src={porewoman} alt="" id='pcWoman'/>
                    </div>
                </div>
                <div className="sldtxtPoreCare">
                    <ul>
                        <li>“We offer high-quality skincare at affordable prices”</li>
                        <li>“We offer high-quality skincare at affordable prices”</li>
                        <li>“We offer high-quality skincare at affordable prices”</li>
                    </ul>
                </div>
                <div className="poreCare-Contents">
                    <section>
                        <div className="poreCare-bundle">
                            <div className="pcBundle-header">
                                <p>Introducing</p>
                                <h1>Pore Care Bundle</h1>
                                <hr />
                            </div>
                            <div className="pcBundle-contents">
                                <div className="pc-bundle-img">
                                    <img src={bundle} alt="" />
                                </div>
                                <ul>
                                    <li id='pToner'>
                                        <span></span>
                                        <div className="pcPrd-dets" id='pTnrDets'>
                                            <h1>Pore Purifying Toner</h1>
                                            <hr />
                                            <p>Specially formulated to target and minimize the appearance of enlarged pores, this toner deeply cleanses, tightens, and balances your skin's pH levels, leaving it smooth and refreshedformulated to target and minimize the appearance of enlarged pores. </p>
                                        </div>
                                    </li>
                                    <li id='pNCream'>
                                        <span></span>
                                        <div className="pcPrd-dets" id='pNCrmDets'>
                                            <h1>Pore Night Cream</h1>
                                            <hr />
                                            <p>Packed with powerful ingredients, it works to tighten and refine pores, reduce excess oil, and enhance skin elasticity. The lightweight, non-greasy formula absorbs quickly, providing intense hydration and a soothing effect. Experience the transformation as your skin becomes softer, more even-toned, and radiant by morning </p>
                                        </div>
                                    </li>
                                    <li id='pCSerum'>
                                        <span></span>
                                        <div className="pcPrd-dets" id='pCSrmDets'>
                                            <h1>Pore Corset Serum</h1>
                                            <hr />
                                            <p>Tighten and refine your pores with the SKINMISO Pore Corset Serum. This powerful serum delivers instant pore-tightening effects while providing long-term care to minimize their appearance. Infused with natural extracts, it balances oil production, smooths skin texture, and boosts hydration.</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div className="pc-prdPromise">
                            <h1>Our Product Promise</h1>
                            <ul>
                                <li>
                                    <img src={organic} alt="" />
                                    <p>100% Vegan</p>
                                </li>
                                <li>
                                    <img src={cruelty} alt="" />
                                    <p>Cruelty Free</p>
                                </li>
                                <li>
                                    <img src={paraben} alt="" />
                                    <p>Paraben Free</p>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <div className="rcfm-desc">
                            <ul>
                                <li id='rcfm-clip'>
                                    
                                </li>
                                <li id='rcfm-desc-txt'>
                                    <h1>Rice Foam Cleansing</h1>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Rice Extract Formula</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Deep Cleansing</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Gentle on Skin</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Antioxidant-Rich</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Foamy Texture</p>
                                </li>
                            </ul>
                            <ul>
                                <li id='rcfm-desc-txt'>
                                    <h1>Pore Purifying Toner</h1>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Pore Minimizing</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Balances pH Levels</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Witch Hazel Extract</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Hydrating Formula</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Non-Irritating</p>
                                </li>
                                <li id='rcfm-clip'>
                                    
                                </li>
                            </ul>
                            <ul>
                                <li id='rcfm-clip'>
                                    
                                </li>
                                <li id='rcfm-desc-txt'>
                                    <h1>Pore Coreset Serum</h1>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Instant Pore Tightening</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Long-Term Pore Care</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Sebum Control</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Hyaluronic Acid</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Natural Plant Extracts</p>
                                </li>
                            </ul>
                            <ul>
                                <li id='rcfm-desc-txt'>
                                    <h1>Pore Zero Night Cream</h1>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Antioxidant Protection</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Deep Hydration</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Overnight Pore Refinement</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Oil Control</p>
                                    <p><IoMdCheckmarkCircleOutline id='pcchckIcon'/> Niacinamide</p>
                                </li>
                                <li id='rcfm-clip'>
                                    
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <div className="hwtuse">
                            <img src={howto} alt="" />
                        </div>
                    </section>
                    <section>
                        <div className="prcrPerfect">
                            <h1>Pore Perfection in Motion</h1>
                            <div className="prcrPrf-content">
                                <ul>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                    <li>
                                        
                                    </li>
                                </ul>
                            </div>
                            <p>
                                Watch as our customers reveal their journey to glowing, healthy skin with the SKINMISO Pore Care Bundle. See the transformation unfold in real-time and discover how our products can help you achieve your skincare goals.
                            </p>
                        </div>
                    </section>
                    <section>
                        <div className="pcRlprd">
                            <h1>Related Products</h1>
                        </div>
                        <div className="pcRlprd-contents">
                            <ul>
                                <li>
                                    <span>Best</span>
                                    <img src={sampleprod1} alt="" />
                                    <div className="pcPrd-info">
                                        <h1>Pore Purifying Toner</h1>
                                        <p>
                                            <div className="pcPrdStar">
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStarHalf id='pcRprdstr'/>
                                            </div>
                                            (4.75)
                                        </p>
                                    </div>
                                    <h2>$17.00</h2>
                                    <h3>4,980 Reviews</h3>
                                </li>
                                <li>
                                    <span>Best</span>
                                    <img src={sampleprod2} alt="" />
                                    <div className="pcPrd-info">
                                        <h1>Pore Zero Night Cream</h1>
                                        <p>
                                            <div className="pcPrdStar">
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStarHalf id='pcRprdstr'/>
                                            </div>
                                            (4.75)
                                        </p>
                                    </div>
                                    <h2>$17.00</h2>
                                    <h3>4,980 Reviews</h3>
                                </li>
                                <li>
                                    <span>Best</span>
                                    <img src={sampleprod3} alt="" />
                                    <div className="pcPrd-info">
                                        <h1>Pure Vitamin C Serum</h1>
                                        <p>
                                            <div className="pcPrdStar">
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStar id='pcRprdstr'/>
                                                <FaStarHalf id='pcRprdstr'/>
                                            </div>
                                            (4.75)
                                        </p>
                                    </div>
                                    <h2>$17.00</h2>
                                    <h3>4,980 Reviews</h3>
                                </li>
                            </ul>
                        </div>
                    </section>
                    <section>
                        <div className="pcSocAcc">
                            <h1>Follow @Skinmiso</h1>
                            <p>Use Hashtag <span>#LoveYourskinMISO</span> for a chance to be featured</p>
                            <div className="pcSocBnnrs">
                                <div className="pcSocMain">
                                    <img src={pcmain} alt="" />
                                </div>
                                <div>
                                    <ul>
                                        <li>
                                            <img src={pcimg1} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg2} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg3} alt="" />
                                        </li>
                                    </ul>
                                    <ul>
                                        <li>
                                            <img src={pcimg4} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg5} alt="" />
                                        </li>
                                        <li>
                                            <img src={pcimg6} alt="" />
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Porecare
