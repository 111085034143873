import {useEffect} from 'react'
// axios
import axios from 'axios';
// cookies
import Cookies from 'js-cookie';

const FetchUser = ({ setCurrentUser, setUserInfo, apiBaseURL,setopenLogModal,openLogModal }) => {

    useEffect(() => {
        fetchcurrentUser();
    }, []);

    const fetchcurrentUser = async () => {
        const currentLogin = localStorage.getItem('currentUser');
        const loginCookie = Cookies.get('loginSessionToken')
        const authCookie = Cookies.get('authToken')
        
        if (currentLogin !== null && typeof loginCookie !== 'undefined' && typeof authCookie !== 'undefined') {
            setCurrentUser(currentLogin);
            
            const userID = { userid: currentLogin };
            try {
                const response = await axios.post(`${apiBaseURL}/user`, userID, {
                    headers: {
                        'Authorization' : `Bearer ${authCookie}`
                    }
                });
                setUserInfo(response.data.data[0]);
                
            } catch (error) {
                console.log(error);
            }
        } else {
            if (openLogModal === '') {
                setTimeout(() => {
                    setopenLogModal('login')
                }, 5000);
            }
            
        }
    };
    

    return null;
};

export default FetchUser;
